@import "./mixins.scss";
.lex-image-picker {
    &-wrapper {
        position: relative;

        &:has(.full-screen) {
            position: unset;
        }
    }

    &-subwrap {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background-color: white;
        padding: 0.5rem;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
        width: 100%;
        height: 100%;
    }

    position: absolute;
    z-index: 105;
    display: flex;
    flex-direction: column;
    width: 30rem;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.5rem;
        width: 100%;
    }

    &-items {
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height: 35.5rem;
        @include scrollbar(transparent, #98acc6);
        overflow-y: auto;
    }

    &-info {
        font-size: 1rem;
    }

    &.full-screen {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 7rem;
        border-radius: unset;

        .lex-image-picker-items {
            max-height: 100%;
        }
    }

    &.constrained {
        position: unset;
        left: unset;
        right: unset;
        top: unset;
        bottom: unset;
        .lex-image-picker-items {
            max-height: 100%;
        }
    }
}
