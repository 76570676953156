@import "./mixins.scss";

.upload-popup {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-panel {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;
        background-color: #ecf0f5;
        border-radius: 0.5rem;

        &.split-docs,
        &.save-docs {
            width: 92%;
        }

        &-title {
            font-size: 1.5rem;
            font-weight: 700;
            text-align: center;
            padding: 1rem;
            // border-bottom: 1px solid #78889D;
            background-color: #cddaeb;
            border-radius: 0.5rem 0.5rem 0 0;
        }

        &-body {
            background-color: #ecf0f5;
            padding: 0 1rem;

            &.split-docs,
            &.save-docs {
                height: calc(100vh - 15rem);
                overflow-y: auto;
                @include scrollbar(transparent, #78889d);
            }
        }

        &-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            padding: 1rem;
        }
    }
}

.lex-block-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 25;
    .lex-block-add {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;


        &-button {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: center;
            justify-content: center;
            gap: 0.25rem;
            width: 24rem;
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            overflow: hidden;
            &:hover {
                // background-color: #78889D;
            }
        }

        &-line {
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #78889D;
        }
    }
}

.lex-block-drag-to-area {
    height: 2rem;
    width: 100%;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.1);
    border: 2px dotted #78889d;
    &.show {
        display: block;
        opacity: 1;
    }
    &.hide {
        display: none;
        opacity: 0;
    }
}

.lex-split-docs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.lex-split-doc {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #78889d;
    background-color: white;
    gap: 1rem;

    &-meta {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 30rem;
    }
    &-preview {
        width: calc(100% - 31rem);
        overflow-y: auto;
        max-height: 28rem;
        @include scrollbar(transparent, #78889d);
    }

    &-label {
        display: flex;
        flex-direction: row;
    }
}

.lex-separator {
    cursor: grab;
    display: flex;
    flex-direction: column;

    &:-webkit-drag {
        background-color: transparent;
        .lex-separator-label-top,
        .lex-separator-label-bottom,
        .lex-separator-icon-remove {
            display: none;
        }
    }

    &-body {
        height: 1px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        position: relative;
    }

    &-line {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #78889d;
    }

    &-icon {
        &-remove {
            // position: absolute;
            font-size: 1.1rem;
            color: red;
        }
    }

    &-label {
        &-top,
        &-bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            // padding: 0 0.5rem;
            gap: 0.25rem;
        }
    }
}
