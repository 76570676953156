@import "./variables.scss";

.lex-files-page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    padding: 0rem 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
}

.lex-files-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    // align-items: center;
    max-height: 100%;
    overflow-y: auto;
    padding-inline: 2rem;
}

.lex-file-preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 50rem;
    gap: 1rem;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    padding-left: 1rem;
    border-radius: 0.5rem;

    &-name {
        color: inherit;
        cursor: pointer;
        text-decoration: none;
        &:hover {
            color: $focus-light;
            font-weight: 500;
        }
    }

    &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
}
