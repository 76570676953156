@mixin flexbox($dir: row, $justify-content: flex-start, $align-items: flex-start, $width: 100%) {
    display: flex;
    flex-direction: $dir;
    justify-content: $justify-content;
    align-items: $align-items;
    width: $width;
}

@mixin scrollbar($foreground-color, $background-color) {
    #{if(&, "&", "*")}::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: $foreground-color;
        border-radius: 10px;
    }
    #{if(&, "&", "*")}::-webkit-scrollbar-track {
        background-color: $foreground-color;
        border-radius: 10px;
    }

    #{if(&, "&", "*")}::-webkit-scrollbar-track-piece {
        background-color: $foreground-color;
    }

    #{if(&, "&", "*")}::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $background-color;
        border-right: 1px solid $foreground-color;
    }
    scrollbar-width: thin;
    scrollbar-color: $background-color $foreground-color;
}
