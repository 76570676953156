@import "./variables.scss";

.lex-add-symbol {
    &-wrapper {
        position: relative;
    }

    &-inner {
        position: absolute;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
        min-width: 11.25rem;
        max-width: 11.25rem;

        &-text {
            padding: 0.5rem;
            border-bottom: 1px solid rgba(25, 29, 36, 0.4);
            text-align: center;
        }

        input {
            margin-inline: 0.5rem;
            width: calc(100% - 1rem);
        }

        &.left {
            right: 0;
        }

        .lex-button {
            margin: 0.5rem;
            margin-top: 0;
        }
    }

    &-item {
        &-list {
            padding: 0.5rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.25rem;
        }
        font-size: 1.25rem;
        width: 2.5ch;
        height: 2.5ch;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
            background-color: #67e3fe;
        }
        &.active {
            background-color: #67e3fe;
            outline: 1px solid rgb(93, 65, 184);
            border: 1px solid #78889d;
        }
        cursor: pointer;
        overflow: hidden;
    }
}
