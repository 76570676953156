.lex-login-page {
    position: fixed;
    inset: 0;
    background: linear-gradient(-45deg, #78889d -50%, #0b0b0b);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    overflow-y: auto;

    &-logo-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    &-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 20rem;
    }

    &-error {
        color: rgb(255, 73, 73);
        text-align: center;
    }

    .form-control,
    .lex-button {
        height: 3rem;
    }

    .form-control {
        background-color: #191d24;
        color: white;
        border-color: #78889d;
        box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);

        transition: background-color 250ms ease-in-out, color 250ms ease-in-out;

        &:hover {
            background-color: #98acc6;
            color: #191d24;
        }

        &:focus {
            background-color: white;
            color: #191d24;
        }

        &::placeholder {
            color: white;
            opacity: 0.5;
        }
    }

    .lex-button {
        font-size: 1.2rem;
        box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .lex-loader-spinner {
        font-size: 0.5rem;
    }
}
