@import "./variables.scss";

.lex-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: white;
    // margin: 10% 20%;
    overflow: hidden;
    border-radius: 0.5rem;
    max-height: 100%;

    &-wrapper {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10% 20%;
        inset: 0;
        z-index: 1999 !important;
        background: rgba(0, 0, 0, 0.8);
    }

    &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        font-size: 1.5rem;
        padding: 1rem;
        background-color: #ecf0f5;
        border-radius: 0.5rem 0.5rem 0 0;
        overflow: hidden;

        &.info {
            background-color: #37cdf0;
        }
        &.success {
            background: $success;
        }
        &.warning {
            background: $warning;
        }
        &.danger {
            background: $danger;
            color: white;
        }
    }

    &-body {
        padding: 1rem;
        overflow-y: auto;
    }

    &-footer {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
}
