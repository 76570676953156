@import "./variables.scss";

.lex-label {
    font-weight: 700;
    padding: 0.125em 0.25em;
    border-radius: 0.25em;
    background: $primary-color;
    color: $secondary-color;

    // variants
    &.success {
        background: $success;
        color: $primary-color;
    }
    &.success-alt {
        background: $success-alt;
        color: $primary-color;
    }
    &.warning {
        background: $warning;
        color: $primary-color;
    }
    &.danger {
        background: $danger;
    }
    &.transparent {
        background: transparent;
        color: $primary-color;
    }

    // sizes
    &.xxxs {
        font-size: 0.5rem;
    }
    &.xxs {
        font-size: 0.625rem;
    }
    &.xs {
        font-size: 0.75rem;
    }

    &.sm {
        font-size: 0.875rem;
    }

    &.md {
        font-size: 1rem;
    }

    &.lg {
        font-size: 1.25rem;
    }

    &.xl {
        font-size: 1.5rem;
    }
}
