.lex-editor-logo {
    display: flex;
    flex-direction: column;

    &-name {
        pointer-events: none;
        user-select: none;
        font-size: 1em;
        font-weight: 100;
        white-space: nowrap;
    }

    &-version {
        pointer-events: none;
        user-select: none;
        font-size: 0.5em;
        font-weight: 300;
        white-space: nowrap;
    }

    &.xs {
        font-size: 0.75rem;
    }

    &.sm {
        font-size: 0.875rem;
    }

    &.md {
        font-size: 1rem;
    }

    &.lg {
        font-size: 1.5rem;
    }

    &.xl {
        font-size: 2rem;
    }

    &.xxl {
        font-size: 4rem;
    }

    &.light {
        color: white;
    }

    &.dark {
        color: #191d24;
        .lex-editor-logo-name {
            font-weight: 300;
        }
    }

    &-visuals {
    }
}
