.lex-rel-selector {
    position: relative;
    z-index: 105;

    &-icon {
        cursor: pointer;
        font-size: 1.5rem;
        transition:
            color 250ms ease-in-out,
            scale 250ms ease-in-out;
        &:hover,
        &.open {
            color: white;
            scale: 1.2;
        }
    }

    &-body {
        position: absolute;
        right: 0;
        top: calc(100% + 0.75rem);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        background-color: white;
        padding: 0.5rem 1rem;
        border-radius: 0 0 0.5rem 0.5rem;
        box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
        width: 40rem;
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
