@import "./variables.scss";

.document-header-revisions {
    position: relative;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &.open {
            font-weight: 700;
            background: white;
            margin-top: -0.5rem;
            margin-right: -0.5rem;
            padding-top: 0.5rem;
            padding-right: 0.5rem;
            padding-left: 1rem;
            width: 26rem;
            border-radius: 0.25rem 0.25rem 0 0;
            background-color: $primary-color-300;
        }
    }

    &-text {
    }

    &-popup {
        position: absolute;
        top: 100%;
        right: 0;
        margin-right: -0.5em;
        padding: 0.5em;
        padding-top: 1em;
        border-radius: 0 0 0.25em 0.25em;
        background-color: $primary-color-300;
        width: 26rem;
    }

    &-entry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5em;
        cursor: pointer;
        padding: 0.5em;
        border-radius: 0.25rem;

        &.active {
            background-color: $primary-color;
        }

        &:hover,
        &:focus {
            background-color: $primary-color;
        }

        &-id {
            width: 1.5em;
            text-align: center;
        }

        &-ts {
            width: 12em;
            text-align: right;
        }

        &-type {
            width: 8em;
        }
    }
}
