.lex-arj-date-picker {
    top: calc(100% + 0.5rem);
    right: 0;
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);

    width: 20rem;
}
