.lex-image-preview {
    // width: 25rem;
    // height: 14rem;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0.75rem;
    gap: 0.25rem;
    cursor: pointer;

    padding: 0.5rem;

    &:hover {
        background: #dde2e9;
        box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.16) !important;
    }

    &.active {
        font-weight: 700;
        background: rgb(157, 199, 241);
        border: 1px solid rgb(128, 59, 255);
        box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.16) !important;
        color: rgb(128, 59, 255);
        &:hover {
            background: rgb(157, 199, 241);
        }
    }

    &-image-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #dde2e9;
        border-radius: 0.5rem;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    &-image {
        max-width: 100%;

        // align-content: center;
    }

    &-uuid {
        // align-self: flex-start;
    }

    &-copy-confirmation {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        z-index: 100;
        color: green;
        background: #ecf0f5;
        padding: 0.25rem 0.5rem;
        border-radius: 0.35rem;
        opacity: 0;
        animation-name: dissapear;
    }

    &-copy-url {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        z-index: 100;
        color: green;
        background: #ecf0f5;
        padding: 0.25rem;
        aspect-ratio: 1;
        border-radius: 0.35rem;
    }
}

@keyframes dissapear {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
