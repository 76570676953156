@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;700;900&display=swap");
@import "./variables.scss";
@import "./mixins.scss";

*,
*::after,
*::before {
    margin: 0;
    box-sizing: border-box;
}

.lex-editor-app {
    font-family: "Lato", sans-serif;
}

*:focus-visible {
    text-decoration: none;
    outline: none;
    border: none;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

a:hover {
    text-decoration: none;
}

.lex-editor-app {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.app-menu {
    width: 100%;
    height: 5rem;
}

.app-content-wrapper {
    width: 100%;
    height: 100%;
    // height: calc(100% - 6.875rem);
    overflow: hidden;
    background-color: #ecf0f5;
    // padding: 0.5rem;
}

.lex-errors {
    position: fixed;
    top: 5rem;
    right: 0;
    z-index: 2001;
}

// buttons
.lex-button {
    position: relative;
    font-weight: 700;
    padding: 0.5em 2em;
    // border: 1px solid black;
    border-radius: 0.3em;
    cursor: pointer;
    background: $primary-color;
    color: $secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-decoration: none;

    transition:
        background-color 250ms ease-in-out,
        color 250ms ease-in-out;

    .lex-loader-spinner {
        font-size: 0.35rem !important;
        margin-left: 0.5rem;
    }

    &-icon {
        align-self: center;
        justify-self: center;
    }

    &.square-2-rem {
        aspect-ratio: 1;
        width: 2rem;
        height: 2rem;
    }

    &.success {
        background: $success;
        color: $primary-color;
        &:hover,
        &:focus {
            background: #c0e666;
        }
        &:focus {
            outline: 1px solid #60830f;
            border: 1px solid #78889d;
        }
    }
    &.success-alt {
        background: $success-alt;
        color: $primary-color;
    }
    &.warning {
        background: $warning;
        color: $primary-color;
    }
    &.danger {
        background: $danger;
    }
    &.neutral {
        background-color: #37cdf0;
        color: $primary-color;
        &:hover,
        &:focus {
            background-color: #67e3fe;
        }
        &:focus {
            outline: 1px solid rgb(93, 65, 184);
            border: 1px solid #78889d;
        }
    }
    &.transparent {
        color: unset;
        background: transparent;

        &.active {
            background: rgba(0, 0, 0, 0.25);
        }

        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    &.disabled {
        cursor: default;
        background-color: #78889d;
        &:hover,
        &:focus {
            background-color: #78889d;
        }
    }

    &.disabled-discrete {
        cursor: default;
        opacity: 0.5;
        &:hover,
        &:focus {
            background-color: inherit;
        }
    }

    &.small {
        padding: 0.5em;
    }

    &.sm {
        font-size: 0.875rem;
    }

    &.md {
        font-size: 0.875rem;
    }

    &.xl {
        font-size: 1.5rem;
    }
}

.lex-table {
    width: 100%;
    margin: 0.5rem 0;

    &-cell {
        padding: 0.125rem 0.25rem;
    }
    &-header-cell {
        padding: 0.25rem;
        padding-bottom: 0.125rem;
    }
}

tr.lex-table-row,
td.lex-table-cell,
th.lex-table-header-cell {
    border: 1px solid black;
}

.lex-spacer {
    &-0_25 {
        width: 0.25em;
        height: 0.25em;
    }
    &-0_5 {
        width: 0.5em;
        height: 0.5em;
    }
    &-1 {
        width: 1em;
        height: 1em;
    }
    &-1_5 {
        width: 1.5em;
        height: 1.5em;
    }
    &-2 {
        width: 2em;
        height: 2em;
    }
}

.ms-auto {
    margin-left: auto !important;
}

.me-auto {
    margin-right: auto !important;
}

.lex-regular-weight {
    font-weight: 400;
}
.lex-bold {
    font-weight: 700;
}
.lex-italic {
    font-style: italic;
}

.lex-underline {
    text-decoration: underline;
}

.lex-strikethrough {
    text-decoration: line-through;
}

.lex-track {
    &-plus {
        // don't use anywhere else
        text-decoration: underline;
        font-weight: 500;
        color: #008000 !important;
    }
    &-minus {
        // don't use anywhere else
        font-style: italic;
        text-decoration: line-through;
        color: #dd1f20 !important;
    }
    &-explain {
        // don't use anywhere else
        font-style: italic;
        color: #3468e3 !important;
    }
}

.lex-align {
    &-left {
        text-align: left;
    }
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
}

.lex-pointer {
    cursor: pointer;
}

.lex-popup {
    position: fixed;
    inset: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

$danger-statuses: A I;
$warning-statuses: B L O v;
$info-statuses: C M;
$success-statuses: V P R r;

.status {
    color: gray;
    white-space: nowrap;
    width: max-content;
}

@each $status in $danger-statuses {
    .status-#{$status} {
        color: red;
    }
}
@each $status in $warning-statuses {
    .status-#{$status} {
        color: orange;
    }
}
@each $status in $info-statuses {
    .status-#{$status} {
        color: rgb(89, 102, 174);
    }
}
@each $status in $success-statuses {
    .status-#{$status} {
        color: green;
    }
}

.lex-home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    height: calc(100% - 10rem);
}

.lex-home-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.lex-home-panel {
    display: flex;
    flex-direction: column;
    width: 42.5%;
    height: 60vh;
    max-height: calc(100% - 2rem);
    overflow: hidden;

    &-heading {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 1.5rem;
        padding: 0 0.25rem;
    }

    &-body {
        padding: 0 0.25rem;
        @include scrollbar(#ecf0f5, #98acc6);
        overflow-y: auto;
    }
}

.lex-icon {
    &-xs {
        font-size: 0.75em;
    }
    &-sm {
        font-size: 0.875em;
    }
    &-md {
        font-size: 1em;
    }
    &-lg {
        font-size: 1.5em;
    }
    &-xl {
        font-size: 2em;
    }
}

$listStyleTypes: none, lower-latin, lower-roman, upper-latin, upper-roman, decimal;

@each $style in $listStyleTypes {
    ol.#{$style} {
        list-style-type: $style;
    }
}

.lex-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0.25rem;
    cursor: pointer;
    border-radius: 0.25em;
}

.hover-red:hover {
    color: red;
    background: rgba(255, 0, 0, 0.1);
}

.hover-green:hover {
    color: green;
    background: rgba(0, 255, 0, 0.1);
}

.hover-blue:hover {
    color: blue;
    background: rgba(0, 0, 255, 0.1);
}
