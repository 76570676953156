.lex-rel-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    .lex-search-result {
        background-color: #ecf0f5;
        color: #191d24;
        &:hover {
            background-color: #ecf0f5;
            cursor: default;
        }
    }

    &-actions {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-left: auto;

        &-item {
            cursor: pointer;
            font-size: 1.125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem;
            border-radius: 0.25rem;

            &.edit {
                color: #37cdf0;
                &:hover {
                    background-color: #37cdf0;
                    color: white;
                }
            }
            &.remove {
                color: red;
                &:hover {
                    background-color: red;
                    color: white;
                }
            }
        }
    }
}
