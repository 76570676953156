.lex-add-link-inner {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    background-color: white;
    // background: transparent;
    padding: 0.5rem;
    gap: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
    min-width: 20rem;
    max-height: 75vh;

    &.nopadding {
        padding: 0;
        gap: 0;
        min-width: unset;
    }

    .lex-search {
        gap: 0;
    }

    .lex-advanced-search {
        position: relative;
        margin-top: 0;
    }

    &.left {
        right: 0;
    }

    &-results {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-height: 70vh;
        overflow-y: auto;
    }
}

.lex-document-picker-results {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
