.lex-search-results {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 0.5rem;
    padding-right: 0.75rem;
    overflow: hidden;
    &.message {
        opacity: 0.5;
    }
}

.lex-search-result {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;
    position: relative;
    border-radius: 0.5rem;
    background-color: white;
    border: 1px solid #707070;
    // overflow: hidden;
    transition: background-color 250ms ease-in-out;

    &:hover,
    &:focus {
        background-color: #cddaeb;
        .lex-search-result-user {
            opacity: 1;
        }
    }

    &.cannot-open {
        opacity: 0.5;
        cursor: not-allowed;

        &:focus,
        &:hover {
            background-color: white;
            .lex-search-result-user {
                opacity: 0.5;
            }
        }
    }

    &.show-user {
        margin-top: 1.5rem;
    }

    &.draft {
        // background-color: rgb(242, 242, 254);
    }

    &.live {
        background-color: rgb(244, 254, 242);

        &:hover,
        &:focus {
            background-color: #d3ebcd;
            .lex-search-result-user {
                opacity: 1;
            }
        }
    }

    &-user {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transform: translateY(-100%);
        transition: opacity 250ms ease-in-out;
        opacity: 0.5;

        &-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
        }
    }

    // .lex-search-result-id {
    //     width: 1rem;
    //     text-align: right;
    // }

    &-main {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;

        &-title {
            font-weight: 700;
            font-size: 1.125em;
        }

        &-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &-statuses {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    &-draft {
        font-weight: 700;
        color: #707070;
    }

    &-delete {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;

        &-icon {
            position: absolute;
            top: 0.25rem;
            right: 0.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition:
                top 250ms ease-in-out,
                right 250ms ease-in-out,
                transform 250ms ease-in-out,
                color 250ms ease-in-out;
        }

        &-text {
            width: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            transition: width 250ms ease-in-out;
            color: white;
        }
        transition:
            width 250ms ease-in-out,
            background-color 1ms 249ms ease-in-out,
            height 1ms 249ms ease-in-out,
            top 1ms 249ms ease-in-out,
            right 1ms 249ms ease-in-out;

        &.expanded {
            background-color: red;
            top: 0;
            right: 0;
            height: 100%;
            width: 5rem;
            border-radius: 0 0.25rem 0.25rem 0;

            transition:
                width 250ms ease-in-out,
                background-color 250ms ease-in-out;

            .lex-search-result-delete {
                &-text {
                    width: 3.5rem;
                    height: 100%;
                }

                &-icon {
                    top: 50%;
                    transform: translateY(-50%);
                    color: white;
                    transition:
                        top 250ms ease-in-out,
                        right 250ms ease-in-out,
                        transform 250ms ease-in-out,
                        color 250ms ease-in-out;
                }
            }
        }
    }

    .lex-emphasize {
        color: red;
    }
}

.lex-doc-override-owner {
    color: red;
    border-radius: 0.25rem;
    padding: 0 0.125rem;
    overflow: hidden;

    &:hover {
        background: red;
        color: white;
    }
}
