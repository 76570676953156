@import "./variables.scss";

.lex-add-bookmark {
    &-wrapper {
        position: relative;
    }

    &-inner {
        position: absolute;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
        min-width: 20rem;
        max-width: 20rem;

        &-text {
            padding: 0.5rem;
            border-bottom: 1px solid rgba(25, 29, 36, 0.4);
            text-align: center;
        }

        input {
            margin-inline: 0.5rem;
            width: calc(100% - 1rem);
        }

        &.left {
            right: 0;
        }

        .lex-button {
            margin: 0.5rem;
            margin-top: 0;
        }
    }

    &-item {
        &-list {
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        background: $neutral;
        &:hover,
        &:focus {
            background-color: #67e3fe;
        }
        &.active {
            outline: 1px solid rgb(93, 65, 184);
            border: 1px solid #78889d;
        }
        width: max-content;
        cursor: pointer;
        // overflow: hidden;
    }
}
