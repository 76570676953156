.app-menu {
    width: 100%;
    height: 5rem;
    background-color: #ecf0f5;
    color: #0b0b0b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    &-logo,
    &-actions,
    .lex-search {
        width: 33%;
    }

    &-logo {
        // width: 3rem;
        // font-size: 1.5rem;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

    &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5em;

        &-icon {
            cursor: pointer;
            display: flex;
            font-size: 1.5rem;
        }
    }
}
