ul {
    list-style: disc;

    &[data-type="squareBulletList"] {
        list-style: none;

        & > li:before
        {
            position: absolute;
            content: "\2610";
            transform: translateX(-150%);
        }
    }

    &[data-type="emptyCircleBulletList"] {
        list-style: circle;
    }

    &[data-type="tickBulletList"] {
        list-style: none;
        & > li:before {
            position: absolute;
            transform: translateX(-150%);
            content: "✓";
        }
    }
}
