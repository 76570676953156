.lex-color-picker {
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    background-color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);

    &-wrapper {
        position: relative;
    }

    &-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.75rem;
    }
    &-palette {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.125rem;

        &-entry {
            cursor: pointer;
            width: 1rem;
            height: 1rem;

            &.active {
                border: 1px solid #78889d;
            }
        }
    }
}
