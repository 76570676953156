$primary-color: #33465c;
$primary-color-300: #6f8baa;
$secondary-color: #e5f7f7;
$secondary-color-100: #d7eded;
$secondary-color-300: #cbe1e1;

$success: #94ce11;
$success-alt: #90e679;
$warning: #e2932c;
$danger: #dd1f1f;

$focus-light: #60830f;
$focus-dark: #c0e666;

$neutral: #37cdf0;
