.lex-banner {
    border-radius: 0.5em;
    padding: 1em 1em 1em 2em;
    width: 30em;
    display: flex;
    flex-direction: column;
    font-size: 1rem;

    svg {
        min-width: 1em;
    }

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
        }
    }

    &-close-icon {
        min-width: 1.5em;
        font-size: 1.5em;
        cursor: pointer;
        &:hover,
        &:focus {
            color: rgb(200, 200, 200);
        }
    }

    &-details {
        border-top: 1px solid white;
        margin-top: 0.5em;
        padding-top: 0.5em;
    }

    &.error {
        background-color: #dd1f20;
        color: white;

        .lex-banner-close-icon {
            &:hover,
            &:focus {
                color: #dd1f20;
                border-radius: 50%;
                background-color: white;
            }
        }
    }

    &.warning {
        background-color: rgb(248, 196, 99);
        color: black;

        .lex-banner-close-icon {
            &:hover,
            &:focus {
                color: rgb(248, 196, 99);
                border-radius: 50%;
                background-color: #191d24;
            }
        }
    }

    &.success {
        background-color: #94ce11;
        color: black;

        .lex-banner-close-icon {
            &:hover,
            &:focus {
                color: #94ce11;
                border-radius: 50%;
                background-color: #191d24;
            }
        }
    }

    &.info {
        background-color: #c6e0ff;
        color: #191d24;

        .lex-banner-close-icon {
            &:hover,
            &:focus {
                color: #c6e0ff;
                border-radius: 50%;
                background-color: #191d24;
            }
        }
    }
}
