.list-style-picker {
    position: absolute;
    z-index: 105;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    background-color: white;
    border-radius: 0.5rem;
    // border: 1px solid black;
    box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);

    &-wrapper {
        position: relative;
    }

    &-option {
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 0.25rem;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}
