@import './variables.scss';

.lex-form-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 0.5rem;

    &-label {
        width: 100%;
    }
    &-value {
        width: 100%;
    }

    &-error {
        align-self: flex-start;
        color: $danger;
    }

    &.force-row {
        width: 100%;
        flex-direction: row;
        gap: 1rem;

        .lex-form-field {
            &-label,
            &-value {
                width: auto;
            }
        }
    }

    &-option {
        padding: 0.25rem;
        background-color: white;
        color: black;
    }

    .form-control {
        border-radius: 0.5rem;
        background-color: #cddaeb;
        border: 1px solid #78889d;

        &:disabled {
            color: #78889d;
        }

        &:focus {
            background-color: #9fe59e;
            outline: 1px solid #50d04e;
            box-shadow: none;
            border: 1px solid #78889d;
        }
        &.open {
            border-radius: 0.5rem 0.5rem 0 0;
            border-bottom: none;
            // &:focus {
            // }
        }

        &.lex-search-text {
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            .form-control {
                border: none;
            }
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
}
