@import "./variables.scss";

.lex-images-page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    padding-left: 2rem;
}

.lex-images-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // gap: 1.33%;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 1rem;
}
