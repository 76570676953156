@import "./variables.scss";
@import "./mixins.scss";

.lex-dropdown {
    &-wrapper {
        display: flex;
        flex-direction: column;
    }

    &-select {
        position: relative;

        & > input {
            box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
            border-radius: 0.5rem 0.5rem 0 0 !important;
        }

        &.up {
            & > input {
                border-radius: 0 0 0.5rem 0.5rem !important;
            }
        }
    }

    &-trigger {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &:hover {
            color: $focus-light;
        }
    }

    &-list {
        border-radius: 0 0 0.5rem 0.5rem;
        background-color: #cddaeb;
        border: 1px solid #78889d;
        box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);

        z-index: 102;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        max-height: 32vh;
        overflow: hidden;

        &.up {
            border-radius: 0.5rem 0.5rem 0 0;
            top: unset;
            bottom: 100%;
        }

        &-items {
            overflow-y: auto;
            @include scrollbar(transparent, #78889d);
        }
    }

    &-item {
        cursor: pointer;
        padding: 0.25rem 1rem;
        background-color: #cddaeb;
        color: #191d25;
        white-space: nowrap;

        &:hover,
        &:focus {
            background-color: #ecf0f5;
        }

        &.active {
            background-color: #83b1e9;
            font-weight: 700;
            cursor: default;
            &:focus {
                background-color: #ecf0f5;
            }
        }
    }
}
