.lex-fallback {
    &-page {
        position: fixed;
        inset: 0;
        z-index: 2000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10rem;
        gap: 10rem;
        font-size: 2rem;
        background-color: #ECF0F5;
    }

    &-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    &-icon {
        font-size: 6rem;
    }

    &-other {
        font-size: 1.5rem;
    }
}
