p[data-type="lex-bookmark"] {
    position: relative;
    background-color: rgb(188, 153, 207);
    width: max-content;
    font-size: 0.75rem;
    padding: 0.125rem 0.5rem !important;
    padding-right: 1rem;
    margin: 0 !important;

    &[type="start"] {
        border-radius: 0.25rem 1rem 1rem 0.25rem;
        border: var(--bookmark-line-thickness) solid black;

        .bookmark-line {
            // TODO = fix algorithm before changing this
            display: none;
            position: absolute;
            font-size: 0;
            padding: 0;
            margin: 0;
            background-color: transparent;
            line-height: 1px;
            width: 0.625rem;
            top: 11px; // maybe make this better
            border: 2px dashed rgb(188, 153, 207);
            left: -0.7rem;
            // height: var(--bookmark-line-height);
            // transform: var(--bookmark-line-rotate-angle);
            border-right: none;
        }
    }

    &[type="end"] {
        border-radius: 1rem 0.25rem 0.25rem 1rem;
        opacity: 0.75;
        .bookmark-line {
            display: none;
        }
    }
}
