.lex-search {
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    position: relative;

    &-icon {
        margin-right: 0.5rem;
    }

    .form-control {
        border-radius: 0.5rem;
        background-color: #cddaeb;
        border: 1px solid #78889d;
        &:focus {
            background-color: #cddaeb;
            outline: none;
            box-shadow: none;
            background-color: #cddaeb;
            border: 1px solid #78889d;
        }
        &.open {
            border-radius: 0.5rem 0.5rem 0 0;
            border-bottom: none;
            // &:focus {
            // }
        }

        &.lex-search-text {
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            .form-control {
                border: none;
            }
        }
    }
}

.lex-advanced-search {
    z-index: 100;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    background-color: #ffffff;
    border: 1px solid #78889d;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 1.25rem;

    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    .lex-search-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .lex-search-label {
            width: 12rem;
            font-size: 1rem;
        }
    }

    .lex-search-actions-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        justify-content: flex-end;
    }
}
