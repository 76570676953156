.lex-rel-add {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgba(25, 29, 36, 0.4);
    width: 50rem;
    min-height: 50rem;
    height: 80vh;

    &-header {
        font-size: 1.5rem;
        color: white;
        background-color: #98acc6;
        padding: 0.5rem 1rem;
    }

    &-body {
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        overflow-y: auto;
    }

    &-search {
        // padding: 0 1em;
        display: flex;
        flex-direction: column;

        .lex-search {
            gap: 0;
            width: 100%;
        }

        .lex-advanced-search {
            position: unset;
            top: unset;
            left: unset;
            right: unset;
            width: 100%;
        }

        &-results {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0.5rem 0;

            &-entry {

                &.selected {
                    .lex-search-result {
                        background-color: #cddaeb;
                        color: #191D24;
                    }
                }
            }
        }
    }

    &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
        margin-top: auto;
    }
}
