@import "./variables.scss";
@import "./mixins.scss";
@import "./document-styles.scss";
@import "./bookmarks.scss";

.document-editor {
    background: white;
    height: calc(100% - 6.875rem);
    display: flex;
    flex-direction: column;

    & > div {
        overflow: auto;
        @include scrollbar(transparent, #78889d);
        height: 100%;
        padding: 1rem;
        z-index: 100;
    }

    .lex-editor-controls {
        height: auto;
        padding: 0.5rem;
        overflow: visible;
        z-index: 101;
    }

    .document-header {
        height: 6.875rem;
        padding: 0 !important;
        overflow: unset;
        z-index: 102;
    }
}

.document-header {
    display: flex;
    flex-direction: column;
    background-color: #0b0b0b;
    color: white;
    height: 6.875rem;

    &-main {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        height: 3.875rem;
        background-color: #0b0b0b;
        padding: 0;
        padding-right: 1rem;

        .lex-loader-spinner {
            font-size: 0.5rem !important;
        }
    }

    &-secondary {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding: 0 1rem;
        height: 3rem;
        background-color: #191d25;
        font-weight: 400;
        font-size: 1rem;
        color: #98acc6;

        .react-datepicker-wrapper {
            width: 5.5rem;
            input {
                border: none;
                padding-left: 0;
            }
        }
    }

    &-entry {
        cursor: default;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        align-items: center;
        white-space: nowrap;

        &.wrap {
            white-space: normal;
        }
    }

    .form-control {
        &.document-header-title {
            padding: 0 1rem;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
            }
        }
        &.document-header-secondary {
            background-color: #191d25;
            color: #98acc6;
            width: max-content;
            min-width: 0.5rem;
            padding: 0;
            border: none;
            font-size: 1rem;
            text-align: right;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // height: auto;
            line-height: 1.1;
            font-weight: 400;
            text-decoration: none;
            &:focus,
            &:hover {
                box-shadow: none !important;
                color: white;
            }
        }
    }

    &-title {
        background-color: #0b0b0b !important;
        color: white !important;
        border: none !important;
        width: calc(100% - 10rem) !important;
        font-size: 2em !important;
        text-overflow: ellipsis;
        height: 2em !important;
        overflow: hidden;
        line-height: 2em !important;
        white-space: nowrap;
        &:focus {
            box-shadow: none !important;
        }
    }

    &-links {
        margin-left: auto;
    }

    &-actions {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    &-not-saved {
        width: 2rem;
        font-size: 1.5rem;
        color: red;
    }

    &-select {
        position: relative;

        &-trigger {
            &:hover {
                color: white;
            }
        }

        &-list {
            z-index: 102;
            position: absolute;
            top: 100%;
            left: 0;
            display: flex;
            flex-direction: column;
            max-height: 50vh;
            overflow: hidden;

            &-items {
                overflow-y: auto;
                @include scrollbar(transparent, #78889d);
            }
        }

        &-item {
            cursor: pointer;
            padding: 0.25rem 1rem;
            background-color: #cddaeb;
            color: #191d25;
            white-space: nowrap;

            &:hover,
            &:focus {
                background-color: #ecf0f5;
            }

            &.active {
                background-color: #ecf0f5;
                font-weight: 700;
                cursor: default;
            }
        }
    }
}

.document-body {
    padding: 1em 1.5em;
    color: $primary-color;
    @include scrollbar($secondary-color, $primary-color);
    height: calc(100% - 4.5em);
    overflow-y: scroll;

    .lex-block {
        font-family: Arial, Helvetica, sans-serif;
    }
}

.document-entry {
    &-editable {
        width: 100%;
        margin: 0;
    }
    border-radius: 0.5rem;

    &.edit-mode {
        cursor: text;
        // opacity: 0.75;
        &:hover {
            background-color: $secondary-color-100;
        }
    }
}

article {
    width: 100%;

    & div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.lex-editor-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.5rem;
    background: white;
    padding: 1em;
    overflow: hidden;
    .lex-editor-icon {
        font-size: 2rem;
    }
}

.lex-editor-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 1rem;
    width: 100%;
    border-bottom: 1px solid $secondary-color-300;
    font-size: 1.25rem;

    .form-select {
        margin-inline: 0.25rem;
    }
}

.lex-editor-icon {
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: unset;
    }
}

.abs-left,
.abs-right {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    color: $primary-color-300;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $primary-color;
    }
}

.abs-right {
    left: unset;
    right: 0.5rem;
}

.lex-add-link-wrapper {
    position: relative;
}
