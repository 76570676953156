.evs-tooltip {
    .tooltip-inner {
        padding: 0.5rem 1rem;
        max-width: unset;
        border-radius: 0.25rem;
    }
}

.tooltip.evs-tooltip-light,
.popover.evs-tooltip-light {
    background: none;
    border: none;
    .tooltip-inner,
    .popover-body,
    .popover-header {
        background-color: rgb(76, 76, 76) !important;
        color: white;

        .change-summary-tooltip-text {
            color: white;
        }
    }
    .tooltip-arrow::before {
        border-bottom-color: rgb(76, 76, 76) !important;
    }

    .tooltip-arrow::after {
        border-bottom-color: rgb(76, 76, 76) !important;
    }

    .popover-arrow::before {
        border-left-color: rgb(76, 76, 76) !important;
    }

    .popover-arrow::after {
        border-left-color: rgb(76, 76, 76) !important;
    }
}

.tooltip.evs-tooltip-dark,
.popover.evs-tooltip-dark {
    .tooltip-inner,
    .popover-body,
    .popover-header {
        background-color: white !important;
        .change-summary-tooltip-text {
            color: black;
        }
    }
    .tooltip-arrow::before {
        border-bottom-color: white !important;
    }

    .tooltip-arrow::after {
        border-bottom-color: white !important;
    }

    .popover-arrow::before {
        border-left-color: white !important;
    }

    .popover-arrow::after {
        border-left-color: white !important;
    }
}
